import React from "react";
import Title from "../components/Title";

const MentionLegal = () => {
  return (
    <section className="mention-legal">
      <Title />
      <div className="mention-content">
        <h2>Mentions légales</h2>
        <div className="asgrin-network">
          <h2>Asgrin Network</h2>
          <p>RCS Toulon 980 859 599 00014</p>
          <p>Code APE : 6201Z</p>
          <p>n°TVA : FR01980859599</p>
        </div>
        <div className="hebergement">
          <h3>Hebergement du site</h3>
          <p>OVHcloud</p>
          <p>Siège social : 2 rue Kellermann - 59100 Roubaix - France</p>
        </div>
        <div className="condition">
          <h3>Condition d'utilisation du site internet www.asgrinnetwork.fr</h3>
          <p>
            Le site et chacun des éléments, y compris mais sans limitation les
            marques, les logos, icônes, infographies, photographies, qui le
            composent sont protégés au titre de la législation internationale de
            la propriété intellectuelle. Les contenus figurant sur le site sont
            la propriété d'Asgrin Network ou d’autres entreprises. Toute
            utilisation, reproduction ou représentation, par quelque procédé que
            ce soit, et sur quelque support que ce soit, de tout ou partie du
            site et/ou des éléments qui le composent n'est pas autorisée sans le
            consentement expresse d'Asgrin Network.
          </p>
        </div>
      </div>
    </section>
  );
};

export default MentionLegal;
