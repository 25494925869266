import React from "react";
import Title from "../components/Title";

const Abouts = () => {
  return (
    <div>
      <Title />
      <h1>ajzea</h1>
      <p>
        Lorem ipsum dolor, sit amet consectetur adipisicing elit. Libero illo
        molestiae animi quod sint, laboriosam sapiente, nemo placeat quia
        commodi autem officia asperiores laborum qui at sed doloremque sit aut
        quibusdam impedit consectetur doloribus! Inventore obcaecati totam, quam
        maxime unde porro quibusdam laborum vitae debitis id, recusandae numquam
        nobis quis?
      </p>
    </div>
  );
};

export default Abouts;
