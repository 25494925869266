import React from "react";
import Title from "../components/Title";
import Mention from "../components/Mention";
import { NavLink } from "react-router-dom";

const Graph = () => {
  return (
    <section className="graph">
      <Title />

      <div className="graph-annonce">
        <h3 className="graph-annonce-title">Nos services infographie</h3>
        <p className="graph-annonce-content-1">
          Vous désirez un logo ? Une planche graphique ? Une identitée
          graphique? N'hésitez plus, faites appel à un de nos graphistes
          passionnés, qui répondra au mieux à vos attentes. Ce que vous
          imaginez, nous le transformons en réalité.
        </p>
        <p className="graph-annonce-content-2">
          Ici commence votre aventure graphique ! Créons une identité pour votre
          entreprise, association. Trouvez avec nous ce qui vous correspond le
          mieux. Que ce soit pour vos cartes de visite, pour votre site, vos
          devis et factures, nous sommes là pour répondre à votre besoin !
        </p>
        <NavLink className="devis" to="/contact">
          <span>&#9755;</span>
          <p>Prendre rendez-vous pour demander un devis</p>
          <span>&#9754;</span>
        </NavLink>
        <Mention />
      </div>
    </section>
  );
};

export default Graph;
