import React from "react";
import { NavLink } from "react-router-dom";

const Mention = () => {
  return (
    <div>
      <div className="footer">
        <NavLink to="/mention-legal">
          <li>Mention legal</li>
        </NavLink>
      </div>
    </div>
  );
};

export default Mention;
