import React from "react";
import Title from "../components/Title";
import { NavLink } from "react-router-dom";
import Mention from "../components/Mention";

const Site = () => {
  return (
    <section className="site">
      <Title />
      <div className="site-web">
        <h3>Nos sites web</h3>
        <div className="site-web-vitrine">
          <h3>Notre forfait clé en main</h3>
          <p>
            Vous souhaitez avoir un site Internet pour augmenter la portée de
            votre entreprise, mais vous ne savez pas par où commencer. Nous
            avons la solution pour vous : votre site vitrine clé en main,
            c'est-à-dire que nous nous occupons de tout ! De la création de vos
            logos, au choix de l’hébergement qui suffit à vos besoins en passant
            par une personnalisation adaptée à tout support (navigateur
            internet, tablette et mobile) qui est à l’image de votre entreprise,
            une installation simple et rapide, un SEO performant pour un bon
            référencement naturel. Le tout est fait en plusieurs phases pour que
            vous puissiez valider chaque étape de la conception et ainsi rester
            au plus près du projet.
          </p>
          <p className="price">
            Version sans page d'administration :
            <span className="price-span"> 2160€ </span> TTC
          </p>
          <p className="price">
            Version avec page d'administration :
            <span className="price-span"> 2760€ </span> TTC
          </p>
        </div>
        <div className="site-web-devis">
          <h3>
            Demandez un devis si vous souhaitez un site plus complexe, avec base
            de donnée, e-commerce, blog etc..
          </h3>
          <p>
            Vous cherchez à vous démarquer de vos concurrents ? Avoir une
            meilleure portée pour toucher un maximum de personnes? Un site
            Internet cohérent avec votre entreprise, association ? Que ce soit
            pour développer le commerce en ligne, la gestion de votre entreprise
            (stock, objectif, etc.), un blog ou tout simplement avoir besoin
            d'une base de données. Nous vous proposons une personnalisation à
            100% en plusieurs étapes pour chacune d’entre elles une validation
            de votre part afin de garantir au maximum votre satisfaction et pour
            un meilleur échange, vous parlerez au même interlocuteur tout au
            long de votre projet, il vous aidera à faire le cahier des charges
            et vous aiguillera au mieux pour aboutir à sa réalisation.
          </p>
          <NavLink className="devis" to="/contact">
            <span>&#9755;</span>
            <p>Prendre rendez-vous pour demander un devis</p>
            <span>&#9754;</span>
          </NavLink>
        </div>
        <div className="site-web-divers">
          <div className="seo">
            <h3>SEO</h3>
            <p>
              Le référencement naturel n’est pas chose aisée, nous faisons tout
              notre possible pour que vous soyez prêt pour affronter vos
              concurrents dans le monde des moteurs de recherche.
            </p>
          </div>
          <div className="responsive">
            <h3>Multi-format</h3>
            <p>
              Votre site sera adapté à tout support ; un style 100% responsive
              vous permettra de toucher autant les ordinateurs, les tablettes ou
              les mobiles ! Nous garantissons un site simple d’utilisation pour
              faciliter la navigation. Un design pensé pour une accessibilité à
              tous.
            </p>
          </div>
          <div className="react">
            <h3>ReactJS</h3>
            <p>
              Tous nos sites sont réalisés en ReactJS pour garantir une
              performance et une rapidité sans égale. Grâce à sa puissance, il
              est très apprécié par les moteurs de recherche.
            </p>
          </div>
        </div>
      </div>
      <Mention />
    </section>
  );
};

export default Site;
