import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./pages/Home";
import Abouts from "./pages/Abouts";
import Site from "./pages/Site";
import Contact from "./pages/Contact";
import MentionLegal from "./pages/MentionLegal";
import Graph from "./pages/Graph";

const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<Abouts />} />
        <Route path="/site" element={<Site />} />
        <Route path="/graphisme" element={<Graph />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="*" element={<Home />} />
        <Route path="/mention-legal" element={<MentionLegal />} />
      </Routes>
    </BrowserRouter>
  );
};

export default App;
