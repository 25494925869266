import React from "react";
import Title from "../components/Title";
import Mention from "../components/Mention";
import { NavLink } from "react-router-dom";

const Home = () => {
  return (
    <section className="home">
      <div className="header">
        <Title />
      </div>
      <div className="home-container">
        <h2>Une solution simple, fiable et à petit prix</h2>
        <div className="home-content">
          <p className="annonce">
            Dans un monde ou le numérique prime, une bonne visibilité est
            nécessaire. Pour cela nous vous proposons une solution simple,
            rapide et a petit prix. Des forfaits pour les créations de site
            vitrine, une personnalisation graphique responsive 100% adapté pour
            vous, un SEO performant vous garantissant un bon référencement. Un
            service client à votre écoute et disponible 7 jours sur 7. Une
            question ? Une demande de devis ? N'hésitez plus ! Contacter nous !
          </p>
          <div className="annonce-site">
            <NavLink to="/site">
              <h3 className="annonce-site-title">
                Forfait Site vitrine clé en main.
              </h3>
              <p className="annonce-site-content">
                Profitez de notre forfait site vitrine clé en main : création de
                votre site Internet d'une à trois pages, d'une personnalisation
                100% responsive, obtention du nom de domaine, hébergement
                pendant un an ainsi qu'un suivit et une maintenance durant la
                première année.
              </p>
            </NavLink>
          </div>
          <div className="annonce-graph">
            <NavLink to="/graphisme">
              <h3 className="annonce-graph-title">Nos service graphisme</h3>
              <p className="annonce-graph-content">
                Vous désirez un logo ? Une planche graphique ? Prenez
                rendez-vous avec l’un de nos collaborateurs pour réaliser vos
                projets!
              </p>
            </NavLink>
          </div>
        </div>
      </div>
      <div className="footer">
        <Mention />
      </div>
    </section>
  );
};

export default Home;
