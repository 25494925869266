import Navigation from "./Navigation";
import { NavLink } from "react-router-dom";

const Title = () => {
  return (
    <div className="title-container">
      <div className="title-content">
        <h1 id="title">
          <NavLink to="/">
            {/* <li className="title">Asgrin Network</li> */}
          </NavLink>
        </h1>
      </div>
      <Navigation />
    </div>
  );
};

export default Title;
