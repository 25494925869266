import React from "react";
import { useRef } from "react";
import emailjs from "@emailjs/browser";
import Mention from "../components/Mention";
import Title from "../components/Title";

const Contact = () => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();
    const formMess = document.querySelector(".form-message");
    emailjs
      .sendForm(
        process.env.REACT_APP_SERVICE,
        process.env.REACT_APP_TEMPLATE,
        form.current,
        process.env.REACT_APP_KEY
      )
      .then(
        (result) => {
          form.current.reset();
          formMess.innerHTML =
            "<p className='succes'> message envoyé, nous vous répondrons dans les meilleurs délais </p>";

          setTimeout(() => {
            formMess.innerHTML = "";
          }, 2500);
        },
        (error) => {
          formMess.innerHTML =
            "<p className='error'> une erreur c'est produite veuillez reessayer</p>";
          setTimeout(() => {
            formMess.innerHTML = "";
          }, 2500);
        }
      );
  };

  return (
    <section className="formulair">
      <Title />
      <div className="form-container">
        <form ref={form} onSubmit={sendEmail}>
          <label id="name">Nom</label>
          <input
            className="small-input"
            type="text"
            name="user_name"
            required
            autoComplete="off"
          />
          <label>Email</label>
          <input
            className="small-input"
            type="email"
            name="user_email"
            required
            autoComplete="off"
          />
          <label htmlFor="numero">Numéro de téléphone</label>
          <input type="number" name="user_number" className="small-input" />
          <label htmlFor="otpion"> Vous nous contactez pour?</label>
          <select name="user_option" id="otpion-form">
            <option name="user_otpion" value="prendre un redez-vous">
              Prendre rendez-vous
            </option>
            <option name="user_option" value="devis site">
              Demander un devis pour un site
            </option>
            <option name="user_option" value="devis graphique">
              demander un devis graphique
            </option>
            <option value="demander des informations">
              demander des informations complémentaires
            </option>
          </select>
          <label>Message</label>
          <textarea name="message" />
          <input className="buttom-form" type="submit" value="Envoyer" />
        </form>
        <div className="form-message"></div>
        <Mention />
      </div>
    </section>
  );
};

export default Contact;
